<template>
  <div>
    <!--begin: Head -->
    <div
      class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <div class="kt-user-card__avatar">
        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        <span
          class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
          >{{ this.currentUser.alias.toUpperCase() }}</span
        >
      </div>
      <div class="kt-user-card__name">{{this.currentUser.name}}</div>
      <div class="kt-user-card__badge">
        <!--<a href="#/profile" class="btn btn-success btn-sm btn-bold btn-font-md"
          >{{ this.currentUser.club.toUpperCase() }}</a
        >-->
      </div>
    </div>
    <!--end: Head -->
    <!--begin: Navigation -->
    <div class="kt-notification">
      <a href="#/profile" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-calendar-3 kt-font-success"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">Mi Perfil</div>
          <div class="kt-notification__item-time">
            Mis datos
          </div>
        </div>
      </a>
      <!--<a href="#/reports" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-sheet kt-font-warning"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            Reports
          </div>
          <div class="kt-notification__item-time">Adressed to you</div>
        </div>
      </a>
      <a href="#/lou" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-cardiogram kt-font-warning"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">L.O.U.</div>
          <div class="kt-notification__item-time">
            Letters of Untertaking
            <span
              class="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded"
              >2 pending</span
            >
          </div>
        </div>
      </a>-->
      <div class="kt-notification__custom kt-space-between">
        <v-btn 
          elevated
          @click="onLogout()"
        >
          <v-icon>mdi-logout</v-icon>
          Salir
        </v-btn>
      </div>
    </div>
    <!--end: Navigation -->
  </div>
</template>

<script>
import { LOGOUT } from "@/store/auth.module";
import { mapGetters } from "vuex";

export default {
  name: "KTDropdownUser",
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),

    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    }
  }
};
</script>
