<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    item-key="id"
    class="elevation-1"
    loading-text="Loading... Please wait"
  >
    <template v-slot:top>
        <v-text-field
          v-if="search_tool"
          v-model="search"
          ref="search"
          append-icon="search"
          label="Buscar..."
          single-line
          hide-details
          clearable
        ></v-text-field>

        <v-spacer></v-spacer>

        <v-dialog v-model="showCompleteChallenge" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Desafío | CARGAR RESULTADO</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row align="center">
                  <v-col cols="5" sm="5" md="5" align="center">
                    <v-chip dark outlined color="blue">
                      {{editedItem.user_from_name}}
                    </v-chip>
                  </v-col>
                  <v-col cols="2" sm="2" md="2" align="center">
                    <v-chip dark>VS</v-chip>
                  </v-col>
                  <v-col cols="5" sm="5" md="5">
                    <v-chip dark outlined color="blue" align="center">
                      {{editedItem.user_to_name}}
                    </v-chip>
                  </v-col>
                </v-row>

                <v-row align="center">
                  <v-col cols="4" sm="4" md="4" align="center">
                    <v-text-field type="number" v-model="editedItem.from_s1"></v-text-field>
                  </v-col>

                  <v-col cols="4" sm="4" md="4" align="center">
                    <v-chip dark> RESULTADO </v-chip>
                  </v-col>

                  <v-col cols="4" sm="4" md="4" align="center">
                    <v-text-field type="number" v-model="editedItem.to_s1"></v-text-field>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close()">Cancel</v-btn>
              <v-btn color="blue darken-1" text :disabled="saving" @click="save(editedItem)">
                Save
                <v-progress-circular color="primary" v-if="saving" :size="20" indeterminate></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </template>

    <template v-slot:item.user_from_name="{ item }">
      <v-chip dark v-if="item.user_from == _self.currentUser.id">
        {{item.user_from_name}}
      </v-chip>
      <v-chip outlined v-else>
        {{item.user_from_name}}
      </v-chip>
    </template>

    <template v-slot:item.tags_from="{ item }">
      <v-chip dark color="grey" v-if="item.status == 'finished' ">
        {{item.set_from_1}}
      </v-chip>
      <v-icon v-if="item.user_from == item.winer" class="text-warning">flaticon2-cup</v-icon>
    </template>

    <template v-slot:item.user_to_name="{ item }">
      <v-chip dark v-if="item.user_to == _self.currentUser.id">
        {{item.user_to_name}}
      </v-chip>
      <v-chip outlined v-else>
        {{item.user_to_name}}
      </v-chip>
    </template>

    <template v-slot:item.tags_to="{ item }">
      <v-chip dark color="grey" v-if="item.status == 'finished' ">
        {{item.set_to_1}}
      </v-chip>
      <v-icon v-if="item.user_to == item.winer" class="text-warning">flaticon2-cup</v-icon>
    </template>

    <template v-slot:item.status="{ item }">
      <v-chip dark outlined label color="black" v-if="item.status == 'challenge' ">
        PENDIENTE ACEPTAR
      </v-chip>

      <v-chip dark outlined label color="green" v-if="item.status == 'accepted' ">
        ACEPTADO
      </v-chip>

      <v-chip dark label color="green" v-if="item.status == 'finished' ">
        FINALIZADO
      </v-chip>

      <v-chip dark label color="red" v-if="item.status == 'rejected' ">
        RECHAZADO
      </v-chip>

      <v-chip dark label color="grey" v-if="item.status == 'timeout' ">
        SIN RESPUESTA
      </v-chip>
    </template>

    <template v-slot:item.actions="{ item }">
      <b-button-group>

        <v-btn icon color="error" :disabled="saving" v-if="item.user_from == _self.currentUser.id && item.status == 'challenge'" @click="deleteChallenge(item.user_to)">
          <v-icon v-if="!saving">mdi-trash-can-outline</v-icon>
          <v-progress-circular color="primary" v-if="saving" :size="20" indeterminate></v-progress-circular>
        </v-btn>
        
        <v-btn icon color="success" :disabled="saving" v-if="item.user_to == _self.currentUser.id && item.status == 'challenge'" @click="acceptChallenge(item.user_from)">
          <v-icon v-if="!saving">mdi-check-outline</v-icon>
          <v-progress-circular color="primary" v-if="saving" :size="20" indeterminate></v-progress-circular>
        </v-btn>
        
        <v-btn icon color="error" :disabled="saving" v-if="item.user_to == _self.currentUser.id && item.status == 'challenge'" @click="rejectChallenge(item.user_from)">
          <v-icon v-if="!saving">mdi-close-outline</v-icon>
          <v-progress-circular color="primary" v-if="saving" :size="20" indeterminate></v-progress-circular>
        </v-btn>
        
        <v-btn text color="green" elevated v-if="item.accepted == '1' ">
          <v-icon>mdi-check-bold</v-icon>
          ACEPTADO
        </v-btn>
        
        <v-btn color="warning" elevated v-if="item.can_complete == '1' &&  item.status == 'accepted' " @click="completeChallenge(item)" >
          <v-icon>mdi-edit</v-icon>
          CARGAR RESULTADO
        </v-btn>
        
      </b-button-group>
    </template>

  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import genericService from "./../../../services/generic.service";

export default {
  data: () => ({
    uri: "challenges",
    singleExpand: false,
    expanded: [],
    formNewtItem: true,
    saving: false,
    dialog: false,
    dialog2: false,
    search: "",
    radios: "",
    editedIndex: -1,
    totalAmount: 0,
    popDatepick1: false,
    popDatepick2: false,
    showCompleteChallenge: false,
    editedItem: {
      id: 0
    },
    defaultItem: {
      id: 0
    },
    switch1: true,

    /* Filtros & Loading */
    loading: true,
    filters: [
      { text: "All", value: 99 }
    ],
  }),

  computed: {
    ...mapGetters(["currentUser"]),
  },

  props: [
      "headers", 
      "items", 
      "search_tool"
  ],

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close();
    }
  },

  methods: {

    reset() {
      this.$emit("initialize");
    },

    completeChallenge(challenge) {
      var _self = this;

      _self.editedItem = Object.assign({}, challenge);
      _self.showCompleteChallenge = true;
    },

    close() {
      var _self = this;
      _self.showCompleteChallenge = false;
    },

    save(challenge) {
      var _self = this;
      if(
        challenge.from_s1 == undefined || challenge.from_s1 == '' || challenge.from_s1 < 0 ||
        challenge.to_s1 == undefined || challenge.to_s1 == '' || challenge.to_s1 < 0
      ) {
        Swal.fire({
          title: "Error!",
          text: "All set results are mandatory!",
          icon: "error"
        });
      } else {
        _self.saving = true;
        genericService.setChallengeResults(
          challenge,
          function (rta) {
            _self.saving = false;
            if (rta != undefined) {
              Swal.fire({
                title: "",
                text: "Challenge updated successfully!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              }).then(function() {
                location.reload();
              });
            }
          }
        );
      }
    },

    rejectChallenge(userId) {
      var _self = this;
      _self.saving = true;

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reject it!'
      }).then((result) => {
        if (result.value) {
          genericService.rejectChallenge(
            _self.currentUser.id,
            userId,
            function (rta) {
              _self.saving = false;
              if (rta != undefined) {
                Swal.fire({
                  title: "Rejected!",
                  text: "Challenge has been rejected",
                  icon: "success",
                }).then(function() {
                    location.reload();
                });
              }
            });
        }else{
          _self.saving = false;
        }
      })
    },

    acceptChallenge(userId) {
      var _self = this;
      _self.saving = true;

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, accept it!'
      }).then((result) => {
        if (result.value) {
          genericService.acceptChallenge(
            _self.currentUser.id,
            userId,
            function (rta) {
              _self.saving = false;
              if (rta != undefined) {
                Swal.fire({
                  title: "Accepted!",
                  text: "Challenge has been accepted",
                  icon: "success",
                }).then(function() {
                    location.reload();
                });
              }
            });
        }else{
          _self.saving = false;
        }
      })
    },

    deleteChallenge(userId) {
      var _self = this;
      _self.saving = true;

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          genericService.deleteChallenge(
            _self.currentUser.id,
            userId,
            function (rta) {
              _self.saving = false;
              if (rta != undefined) {
                Swal.fire({
                  title: "Deleted!",
                  text: "Challenge has been deleted",
                  icon: "success",
                }).then(function() {
                    location.reload();
                });
              }
            });
        }else{
          _self.saving = false;
        }
      })
    }

  },
  mounted() {}
};
</script>
