<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <v-container fluid class="p-5">
            <h3>Hola {{ currentUser.name }}</h3>
            <br />
            <p>
              Editar mi perfil
            </p>
            <br />
            <v-form ref="form" v-model="code1.valid" lazy-validation>
              <div class="row">
                <div class="col-md-6">
                  <v-text-field
                    v-model="code1.name"
                    :counter="30"
                    :rules="code1.nameRules"
                    label="Nombre completo"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="code1.alias"
                    label="Alias"
                    required
                  ></v-text-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <v-text-field
                    v-model="code1.phone_number"
                    :rules="code1.nameRules"
                    label="Teléfono"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="code1.cellphone_number"
                    :rules="code1.nameRules"
                    label="Celular (Pais_Area_Número) (Ej.: 5491125258383)"
                    required
                  ></v-text-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <v-text-field
                    v-model="code1.mail"
                    :rules="code1.emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="code1.pass"
                    :counter="15"
                    label="Nueva contraseña"
                  ></v-text-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <v-text-field
                    v-model="code1.values"
                    label="Values"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="code1.adress"
                    label="Domicilio"
                    required
                  ></v-text-field>
                </div>
              </div>

              <v-btn v-on:click="submit" color="success" class="mr-4">
                Save
              </v-btn>
            </v-form>
          </v-container>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import genericService from "./../../services/generic.service";

export default {
  data() {
    return {
      uri: "/users",
      code1: {
        valid: true,
        name: "",
        alias: "",
        phone_number: "",
        cellphone_number: "",
        mail: "",
        pass: "",
        values: "",
        adress: "",
        nameRules: [
          v => !!v || "Name is required",
          v => (v && v.length <= 30) || "Name must be less than 30 characters"
        ],
        aliasRules: [
          v => !!v || "Name is required",
          v => (v && v.length <= 30) || "Name must be less than 30 characters"
        ],
        emailRules: [
          v => !!v || "E-mail is required",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ]
      }
    };
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Perfil" }]);
    this.getCurrentUser();
  },

  methods: {
    submit: function(e) {
      e.preventDefault();
      var editedUser = this.code1;
      
      if(editedUser.pass == "") {
        this.$delete(editedUser, 'pass');
      }

      genericService.updateRecord(this.uri+"/" + this.code1.id, editedUser, function(){
        Swal.fire({
          title: "",
          text: "User has been successfully saved!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });
      });
    },

    getCurrentUser() {
      var _self = this;
      genericService.getRecord(this.uri, _self.currentUser.id, function(record){
        _self.code1 = record;
        _self.code1.pass = "";
      });
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  }
};
</script>
