<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <RankingList
            :headers="headersRanking"
            :items="itemsRanking"
            :time_to_challenge="timeToChallenge"
            @initialize="initialize"
            :search_tool="true"
          ></RankingList>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import RankingList from "@/views/pages/ranking/RankingList.vue";
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";

export default {
  components: {
    RankingList,
  },

  data: () => ({
    uri: "ranking",
    headersRanking: [
      { text: "#", value: "ranking" , sortable: false , width: '50%' },
      { text: "", value: "view_history" , sortable: false , width: '5%' },
      { text: "Nombre", value: "user" , sortable: false , width: '10%' },
      { text: "Puntos", value: "points" , sortable: false , width: '10%' },
      { text: "Acciones", value: "can_challenge", sortable: false , width: '10%' },
      { text: "P.G.", value: "pg" , sortable: false , width: '5%' },
      { text: "P.P.", value: "pp" , sortable: false , width: '5%' },
      { text: "G.G.", value: "gg" , sortable: false , width: '5%' },
      { text: "G.P.", value: "gp" , sortable: false , width: '5%' },
      { text: "D.A.", value: "da" , sortable: false , width: '5%' },
      { text: "D.R.", value: "dr" , sortable: false , width: '5%' }
    ],
    itemsRanking: [],
    timeToChallenge:""
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;

      setTimeout(() => {
        genericService.getRanking(_self.currentUser.id, function(records){
            _self.itemsRanking = records.items;
            _self.timeToChallenge = records.time_to_challenge;
        });
      }, 400);

    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Ranking" }]);
  },

  computed: {
      ...mapGetters(["currentUser"]),
  },
};
</script>
