<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    item-key="id"
    class="elevation-1"
    loading-text="Loading... Please wait"
    :footer-props="{
      'items-per-page-options': [100, 200, 300, 400, 500, -1]
    }"
    :items-per-page="100"
    :mobile-breakpoint="0"
  >
    <template v-slot:top>
        <v-text-field
          v-if="search_tool"
          v-model="search"
          ref="search"
          append-icon="search"
          label="Buscar..."
          single-line
          hide-details
          clearable
        ></v-text-field>

        <v-spacer></v-spacer>

        <v-btn color="primary" elevated v-if="currentUser.role == 'Admin' " @click="$router.push('users')">
          <v-icon>mdi-account-edit</v-icon>
          Users
        </v-btn>

      <!-- Time to Challenge -->
      <b-button-group v-if="time_to_challenge != false">
        <v-btn color="primary" elevated>
          <v-icon>mdi-clock-outline</v-icon>
          {{time_to_challenge.txt}}
        </v-btn>
      </b-button-group>

      <b-button-group v-if="time_to_challenge != false">
        <v-btn dark color="purple" elevated>
          {{time_to_challenge.days}} días
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn dark  color="purple" elevated>
          {{time_to_challenge.hours}} horas
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn dark  color="purple" elevated>
          {{time_to_challenge.minutes}} minutos
        </v-btn>
      </b-button-group>
      <!-- Time to Challenge -->

      <!-- Show History -->
      <v-dialog v-model="showHistory">
          <v-card>
            <v-card-title>
              <span class="headline">Historial de Puntos</span>
            </v-card-title>

            <v-data-table
                :headers="historyHeaders"
                :items="historyItems"
                item-key="id"
                class="elevation-1"
                loading-text="Loading... Please wait"
                :footer-props="{
                  'items-per-page-options': [100, 200, 300, 400, 500, -1]
                }"
                :items-per-page="100"
            >

            <template v-slot:item.tags_from="{ item }">
              <v-chip dark color="grey" v-if="item.challenge_status == 'finished' ">
                {{item.set_from_1}}
              </v-chip>
              <v-icon v-if="item.challenge_status == 'finished' && item.user_from == item.winer" class="text-warning">flaticon2-cup</v-icon>
            </template>

            <template v-slot:item.tags_to="{ item }">
              <v-chip dark color="grey" v-if="item.challenge_status == 'finished' ">
                {{item.set_to_1}}
              </v-chip>
              <v-icon v-if="item.challenge_status == 'finished' && item.user_to == item.winer" class="text-warning">flaticon2-cup</v-icon>
            </template>

            <template v-slot:item.challenge_status="{ item }">
              <v-chip dark outlined label color="black" v-if="item.challenge_status == 'challenge' ">
                PENDIENTE ACEPTAR
              </v-chip>

              <v-chip dark outlined label color="green" v-if="item.challenge_status == 'accepted' ">
                ACEPTADO
              </v-chip>

              <v-chip dark label color="green" v-if="item.challenge_status == 'finished' ">
                FINALIZADO
              </v-chip>

              <v-chip dark label color="red" v-if="item.challenge_status == 'rejected' ">
                RECHAZADO
              </v-chip>

              <v-chip dark label color="grey" v-if="item.challenge_status == 'timeout' ">
                SIN RESPUESTA
              </v-chip>
            </template>
          
            </v-data-table>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </template>

    <template v-slot:item.user="{ item }">
      <v-chip dark v-if="item.user_id == _self.currentUser.id">
        {{item.user}}
      </v-chip>
      <v-chip outlined v-else>
        {{item.user}}
      </v-chip>
    </template>

    <template v-slot:item.view_history="{ item }">
      <v-icon color="brown" v-if="(currentUser.role == 'Admin') || (item.user_id == _self.currentUser.id)" @click="viewHistory(_self.currentUser.id, item.user_id)" >
        mdi-clock
      </v-icon>
    </template>

    <template v-slot:item.points="{ item }">
      <v-chip dark>
        {{item.points}}
      </v-chip>
    </template>

    <template v-slot:item.can_challenge="{ item }">
      <b-button-group>
        
        <v-btn color="warning" elevated :disabled="saving" v-if="item.can_challenge == '1' && time_to_challenge == false " @click="createChallenge(item.user_id)">
          <v-icon v-if="!saving">mdi-arrow-collapse</v-icon>
          <v-progress-circular color="primary" v-if="saving" :size="20" indeterminate></v-progress-circular>
          DESAFIAR
        </v-btn>

        <v-btn color="warning" elevated disabled v-if="item.can_challenge == '0' && time_to_challenge == false " >
          DESAFÍO EN CURSO
        </v-btn>

      </b-button-group>
    </template>

  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import genericService from "./../../../services/generic.service";

export default {
  data: () => ({
    uri: "ranking",
    showHistory: false,
    saving: false,
    singleExpand: false,
    expanded: [],
    historyItems: [],
    historyHeaders: [
      { text: "Fecha", value: "date_modified" , sortable: false },
      { text: "Estado", value: "challenge_status" , sortable: false },
      { text: "Puntos", value: "points" , sortable: false },
      { text: "P.G.", value: "pg" , sortable: false },
      { text: "P.P.", value: "pp" , sortable: false },
      { text: "G.G.", value: "gg" , sortable: false },
      { text: "G.P.", value: "gp" , sortable: false },
      { text: "D.A.", value: "da" , sortable: false },
      { text: "D.R.", value: "dr" , sortable: false },
      { text: "Desafiante", value: "user_from_name" , sortable: false },
      { text: "", value: "tags_from" , sortable: false },
      { text: "Desafiado", value: "user_to_name" , sortable: false },
      { text: "", value: "tags_to" , sortable: false }
    ],
    formNewtItem: true,
    dialog: false,
    dialog2: false,
    search: "",
    radios: "",
    editedIndex: -1,
    totalAmount: 0,
    popDatepick1: false,
    popDatepick2: false,
    editedItem: {
      id: 0,
      user: ""
    },
    defaultItem: {
      id: 0,
      user: ""
    },
    switch1: true,

    /* Filtros & Loading */
    loading: true,
    filters: [
      { text: "All", value: 99 }
    ],
  }),

  computed: {
    ...mapGetters(["currentUser"]),
  },

  props: [
      "headers", 
      "items", 
      "search_tool",
      "time_to_challenge"
  ],

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close();
    }
  },

  methods: {

    reset() {
      this.$emit("initialize");
    },

    close() {
      this.showHistory = false
    },

    createChallenge(userId) {
      var _self = this;
      _self.saving = true;

      genericService.createChallenge(
        _self.currentUser.id,
        userId,
        function (rta) {
          _self.saving = false;
          if (rta != undefined) {
            var wappUrl = "https://wa.me/"+rta.to_user_mobile_phone_number+"?text=Hola%21%20He%20creado%20un%20desaf%C3%ADo%20de%20Squash%21%0APara%20poder%20aceptarlo%20o%20rechazarlo%2C%20ingres%C3%A1%20en%3A%20https%3A%2F%2Fbacsquash.com.ar%2F%23%2Fchallenges";

            Swal.fire({
              title: "",
              text: "Challenge created successfully!",
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "Cerrar",
              confirmButtonClass: "btn btn-secondary",

              cancelButtonText: "Enviar aviso por Whatsapp!",
              cancelButtonClass: "btn btn-success",
              cancelButtonColor: "#318143"
            }).then((result) => {
                if (result.isConfirmed) {
                  location.reload();
                } else {
                  window.open(wappUrl, '_blank');
                  location.reload();
                }
              });
          }
        }
      );
    },

    viewHistory(currentUser, selectedUserId) {
      var _self = this;
      _self.showHistory = true;

      genericService.getHistory(
        currentUser,
        selectedUserId,
        function (rta) {
          _self.historyItems = rta;
        }
      );
    }

  },
  mounted() {}
};
</script>
